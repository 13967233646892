import React, { useEffect, Fragment, useState, useContext } from "react"

import Section from "../../Elements/Section"
import Address from "../../PersonalDetails/Address"
import FormTextArea from "../../Elements/Form/FormTextarea"
import { resetDispensingChannelValues } from "../services/dispensingChannels"

import FormCheckbox from "elements/Form/FormCheckbox"
import FormStyledRadio from "elements/Form/FormStyledRadio"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faMapMarkedAlt,
  faHome,
  faBuilding,
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons"
import { Link } from "gatsby"
import zipCodeOptions from "../utils/zipCodes.json"
import FormSelect from "../../Elements/Form/FormSelect"
import { AppContext } from "../../../context/AppContext"

import { useAddresses } from "../../PersonalDetails/hooks/useAddresses"

const agreeContactPersonBlurb =
  "If I or my authorized person to receive is not around, I authorize anyone in my delivery address to receive the medicines."

const DeliveryAddressSection = ({
  values,
  addressFieldNames,
  handleChange,
  setFieldValue,
  addresses,
}) => {
  const { dispatch } = useContext(AppContext)
  const [zipCodes, setZipCodes] = useState([])
  let {
    addressType,
    streetAddress,
    city,
    province,
    index,
    zipCode,
  } = values.deliveryAddress

  const airtableAddresses = useAddresses()
  
  const handleDeliverySelect = (address, index) => {
    setFieldValue("deliveryAddress.addressType", address.type)
    setFieldValue("deliveryAddress.streetAddress", address.streetAddress)
    setFieldValue("deliveryAddress.city", {
      value: address.city,
      label: address.city,
    })
    setFieldValue("deliveryAddress.province", {
      value: address.province,
      label: address.province,
    })
    setFieldValue("deliveryAddress.barangay", {
      value: address.barangay || "",
      label: address.barangay || "",
    })
    setFieldValue("deliveryAddress.index", index || 0)
    setFieldValue("deliveryAddress.primary", address.barangay || false)
    setFieldValue(
      "deliveryAddress.zipCode",
      province?.value !== address?.province ? { value: null } : zipCode
    )
  }

  let valueKey = `${addressType || ""}${streetAddress || ""}${
    city?.value || ""
  }${province?.value || ""}`

  useEffect(() => {
    if (values?.overrideAddress?.length === 0) {
      let currentIndex = index || 0
      if (addresses?.[currentIndex])
        handleDeliverySelect(addresses?.[currentIndex], currentIndex)
    } else {
      if (!valueKey && addresses?.[0]) handleDeliverySelect(addresses?.[0], 0)
    }
  }, [values.overrideAddress])

  useEffect(() => {
    if (province?.value && city?.value) {
      let zipCodesByProvince = zipCodeOptions?.filter(
        (zipCode) => zipCode?.province === province?.value
      )
      let zipCodesByCity = zipCodesByProvince?.filter((zipCode) => {
        let currentProvince = airtableAddresses?.find(
          (location) => location?.Province === province?.value
        )
        let currentCities =
          currentProvince?.Cities?.filter?.((locationCity) => {
            let barangays = locationCity?.Barangays?.includes(zipCode?.barangay)
            return !!barangays
          })?.map((locationCity) => locationCity?.City) || []

        return currentCities.length > 0 && currentCities?.includes(city?.value)
      })
      let finalZipCodes =
        zipCodesByCity?.length > 0 ? zipCodesByCity : zipCodesByProvince

      setZipCodes(
        finalZipCodes?.map((zipCode) => ({
          label: `${zipCode?.zipCode} - ${zipCode?.barangay}`,
          value: zipCode?.zipCode,
        }))
      )
    }
  }, [province, city])
  
  return (
    <Section title="Delivery Address">
      {addresses && (
        <FormCheckbox
          name="overrideAddress"
          values={values.overrideAddress}
          options={["Use different address"]}
          isRequired
        />
      )}
      {values?.overrideAddress.length === 0 &&
        addresses?.map((address, index) => {
          let { type, streetAddress, city, province } = address
          let addressKey = `${type}${streetAddress}${city}${province}`

          return (
            <FormStyledRadio
              isActive={valueKey === addressKey}
              onClick={() => {
                handleDeliverySelect(address, index)
              }}
            >
              <div className="is-size-7">
                <p className="is-size-6 ml-0">
                  <span className="icon mr-1">
                    <FontAwesomeIcon
                      icon={address.type === "home" ? faHome : faBuilding}
                    />
                  </span>
                  Address Type:{" "}
                  <b>
                    {address.type}{" "}
                    {address.primary && (
                      <span className="tag is-rounded">Default</span>
                    )}
                  </b>
                </p>
                <p className="is-size-6">
                  <span className="icon mr-1">
                    <FontAwesomeIcon icon={faMapMarkedAlt} />
                  </span>
                  {address.street || address.streetAddress},{" "}
                  {address.barangay ? `${address.barangay}, ` : ""}
                  {address.city}, {address.province}{" "}
                  {address.notes ? `(${address.notes})` : ""}
                </p>
                {!address?.barangay ? (
                  <p className="is-size-6 mt-1">
                    <span className="icon mr-1 has-text-danger">
                      <FontAwesomeIcon icon={faExclamationTriangle} />
                    </span>
                    <span className="has-text-danger has-text-weight-bold">
                      This address is not updated.
                    </span>{" "}
                    <Link
                      to="/profile/edit-address"
                      state={{
                        nextPath: "/epharmacy/checkout",
                        backPath: "/epharmacy/checkout",
                        address: {
                          ...address,
                          index: index,
                        },
                      }}
                    >
                      Update address
                    </Link>
                  </p>
                ) : null}
              </div>
            </FormStyledRadio>
          )
        })}
      {(!addresses ||
        addresses?.length === 0 ||
        values?.overrideAddress.length > 0) && (
        <Fragment>
          <Address
            fieldNames={addressFieldNames}
            values={values.deliveryAddress}
            isRequired
            setFieldValue={setFieldValue}
            isNationwide
            onChange={{
              province: ({ setFieldValue, value }) =>
                resetDispensingChannelValues({ setFieldValue, value }),
            }}
          />
        </Fragment>
      )}
      <FormSelect
        options={zipCodes}
        isSelectable
        label="ZIP Code"
        name="deliveryAddress.zipCode"
        value={zipCode}
        helper="Please input your ZIP code if you can't find it in the list."
        onChange={(selectedZipCode) =>
          setFieldValue("deliveryAddress.zipCode", selectedZipCode)
        }
      />
      <FormTextArea
        name="deliveryAddress.notes"
        label="Delivery Notes"
        value={values?.deliveryAddress?.notes}
        placeholder={`You can provide special instructions here:\n- Eligibility for free goods\n- Billing name and address\n- Packaging request`}
      />
      <FormCheckbox
        name="agreeContactPerson"
        values={values?.agreeContactPerson}
        options={[agreeContactPersonBlurb]}
        isRequired
        onChange={(event) =>
          dispatch({
            type: `SAVE_AGREE_CONTACT_PERSON`,
            payload: event.target.checked ? [agreeContactPersonBlurb] : [],
          })
        }
      />
    </Section>
  )
}

export default DeliveryAddressSection
