import React, { Fragment, useContext } from "react"
import { isPdf } from "../../Upload/services/uploadDocuments"
import Img from "gatsby-image"
import classNames from "classnames"

import { AppContext } from "../../../context/AppContext"
import { usePdfThumbnail } from "../hooks/usePdfThumbnail"

import styles from "../utils/personaldetails.module.scss"

const ImageCard = ({ image, type }) => {
  const data = usePdfThumbnail()
  const pdfThumbnail = data.pdfThumbnail.childImageSharp.fixed
  const { dispatch, state } = useContext(AppContext)

  const handleDeleteCard = async (type) => {
    await dispatch({
      type: "SAVE_CONTEXT_TO_SESSION",
      payload: {
        ...state,
        scpwdId: {
          ...state.scpwdId,
          [type.toLowerCase()]: {},
        },
      },
    })
  }

  return (
    <Fragment>
      {isPdf(image.path) ? (
        <div
          className={classNames(
            "is-flex",
            styles["scpwdId__pdfThumbnailContainer"]
          )}
        >
          <Img
            fixed={pdfThumbnail}
            className={classNames(styles["scpwdId__pdfThumbnailImage"])}
          />
          <p>{image.filename}</p>
        </div>
      ) : (
        <figure className="image is-3by2">
          <img src={image.path} alt={image.oldName} />
        </figure>
      )}
      <center>
        <button
          type="button"
          name={image.type}
          className="button mt-1 is-danger is-outlined"
          onClick={() => handleDeleteCard(type)}
        >
          Remove
        </button>
      </center>
    </Fragment>
  )
}

export default ImageCard
