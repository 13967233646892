import React from "react"
import Img from "gatsby-image"
import classNames from "classnames"

import styles from "../utils/elements.module.scss"

const FormStyledRadio = ({ isActive, onClick, label, logo, children }) => {
  return (
    <div
      className={classNames(
        styles[`styledRadio__option${isActive ? "Active" : ""}`]
      )}
      onClick={onClick}
    >
      <div className="columns is-vcentered pt-1 pb-1 is-mobile ml-1 ml-0-mobile">
        <div className={classNames(styles["styledRadio__radioButton"])}>
          {isActive && (
            <div
              className={classNames(styles["styledRadio__radioButtonChecked"])}
            ></div>
          )}
        </div>
        <div className="column">
          <div
            className={classNames(
              "is-flex pr-1 is-align-items-center is-flex-wrap mt-1-mobile",
              styles["styledRadio__label"]
            )}
          >
            {logo && <Img fixed={logo} />}
            <div className="column pl-1">
              {label && (
                <p className="is-size-5 has-text-weight-bold">{label}</p>
              )}
              {children}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FormStyledRadio
