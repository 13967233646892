import { useStaticQuery, graphql } from "gatsby"

const PDF_THUMBNAIL_QUERY = graphql`
  {
    pdfThumbnail: file(relativePath: { eq: "pdf.png" }) {
      childImageSharp {
        fixed(height: 300, width: 300, quality: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`

export const usePdfThumbnail = () => {
  const data = useStaticQuery(PDF_THUMBNAIL_QUERY)

  return data
}
