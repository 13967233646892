import React, { Fragment } from "react"
import FormInput from "../Elements/Form/FormInput"
import FormCheckbox from "../Elements/Form/FormCheckbox"

const contactPersonBlurb = "Contact this number for order and delivery updates"

const PatientDetails = ({ values = {}, onChange }) => {
  return (
    <Fragment>
      <FormInput
        name="firstName"
        label="First Name"
        placeholder="Juan"
        isRequired
      />
      <FormInput
        name="lastName"
        label="Last Name"
        placeholder="Dela Cruz"
        isRequired
      />
      <FormInput
        name="emailAddress"
        label="Email Address"
        placeholder="jdelacruz@mail.com"
        isRequired
      />
      <FormInput
        name="mobileNumber"
        label="Mobile Number"
        placeholder="09123456789"
        isRequired
        maxLength={11}
        isNumeric
      />
      <FormCheckbox
        name="contactPerson"
        values={values?.contactPerson}
        options={[contactPersonBlurb]}
        isRequired
      />
      {values?.contactPerson.length === 0 && (
        <section>
          <FormInput
            name="authorizedPerson.fullName"
            label="Contact Person"
            isRequired
            placeholder="Juan Dela Cruz"
          />
          <FormInput
            name="authorizedPerson.contactNumber"
            label="Mobile Number of Contact Person"
            placeholder="09123456789"
            isRequired
            maxLength={15}
            isNumeric
          />
        </section>
      )}
      <FormInput
        name="landlineNumber"
        label="Landline"
        placeholder="88881234"
        maxLength={11}
        isNumeric
        autocomplete="off"
      />
      <FormInput
        name="patientCode"
        label="CarePlus Patient Code"
        helper="Please provide your CarePlus Patient Code to help us track your previous purchases and expedite the verification of your free goods."
      />
    </Fragment>
  )
}

export default PatientDetails
