import { initialValues } from "../utils/dispensingChannelsSchema"

export const GENERIC_OPTION_NAME =
  "(Recommended) Best dispensing channel with available stocks and can deliver on your preferred time"

export const bestDispensingChannelPaymentOptions = [
  {
    label: "Bank Transfer/Cash Deposit",
    image: "bank_big",
  },
  {
    label: "Cash on Delivery",
    image: "cod_big",
    followUps: [
      {
        followUpType: "input",
        type: "number",
        label: "Change for:",
        name: "changeFor",
        hideOptional: true,
        maxLength: 10,
        hasAddons: true,
        addonLeft: "P",
      },
    ],
  },
  {
    label: "Credit Card on Delivery",
    image: "card_big",
    followUps: [
      {
        followUpType: "input",
        type: "text",
        label: "Issuing Bank",
        name: "creditCardBank",
        hideOptional: true,
        isRequired: true,
        hasAddons: false,
      },
      {
        followUpType: "radio",
        type: "radio",
        label: "Credit Card Type",
        name: "creditCardType",
        options: ["VISA", "Mastercard", "JCB", "Unionpay", "BancNet", "Others"],
        isRequired: true,
        hideOptional: true,
        hasAddons: false,
      },
    ],
  },
]

export const resetDispensingChannelValues = ({ setFieldValue, value }) => {
  const isNationwide = value !== "Metro Manila"

  setFieldValue("paymentOption", initialValues?.paymentOption)
  setFieldValue("changeFor", initialValues?.changeFor)
  setFieldValue("creditCardBank", initialValues?.creditCardBank)
  setFieldValue("creditCardType", initialValues?.creditCardType)
  setFieldValue("courierOption", initialValues?.courierOption)
  setFieldValue(
    "preferredCourierOption",
    isNationwide ? "" : initialValues?.preferredCourierOption
  )
  setFieldValue("isSameDay", initialValues?.isSameDay)
  setFieldValue("preferredDeliveryDate", initialValues?.preferredDeliveryDate)
  setFieldValue("preferredDeliveryTime", initialValues?.preferredDeliveryTime)
}
