import * as Yup from "yup"

export const deliveryDetailsValidationSchema = Yup.object().shape({
  firstName: Yup.string().required("This field is required"),
  lastName: Yup.string().required("This field is required"),
  mobileNumber: Yup.string()
    .min(11, "Please input a valid mobile number in this format: 09991234567")
    .max(11, "Please input a valid mobile number in this format: 09991234567")
    .matches(
      /^09[0-9]{9}$/,
      "Please input a valid mobile number in this format: 09991234567"
    )
    .required("This field is required"),
  emailAddress: Yup.string()
    .email("Please input a valid email")
    .required("This field is required"),
  deliveryAddress: Yup.object().shape({
    streetAddress: Yup.string().required("This field is required"),
    city: Yup.object().shape({
      value: Yup.string().required("This field is required").nullable(),
    }),
    province: Yup.string().required("This field is required"),
    addressType: Yup.string().required("This field is required"),
    barangay: Yup.object().shape({
      value: Yup.string().required("This field is required").nullable(),
    }),
  }),
  authorizedPerson: Yup.object().when("contactPerson", {
    is: (contactPerson) => contactPerson.length === 0,
    then: Yup.object().shape({
      fullName: Yup.string().required("This field is required"),
      contactNumber: Yup.string().required("This field is required"),
    }),
  }),
  hospitalName: Yup.object().shape({
    value: Yup.string().required("This field is required").nullable(),
  }),
  otherHospital: Yup.string().when("hospitalName", {
    is: (hospitalName) => hospitalName?.value === "Other",
    then: Yup.string().required("This field is required"),
  }),
  doctorName: Yup.string().required("This field is required"),
  otherCallFrequency: Yup.string().when("howOftenCanWeCall", {
    is: "Other (please specify)",
    then: Yup.string().required("This field is required"),
  }),
})

export const specialOrderDeliveryDetailsValidationSchema = Yup.object().shape({
  doctorAccountName: Yup.string().required("This field is required"),
  mobileNumber: Yup.string()
    .min(11, "Please input a valid mobile number in this format: 09991234567")
    .max(11, "Please input a valid mobile number in this format: 09991234567")
    .matches(
      /^09[0-9]{9}$/,
      "Please input a valid mobile number in this format: 09991234567"
    )
    .required("This field is required"),
  emailAddress: Yup.string()
    .email("Please input a valid email")
    .required("This field is required"),
  deliveryAddress: Yup.object().shape({
    streetAddress: Yup.string().required("This field is required"),
    city: Yup.object().shape({
      value: Yup.string().required("This field is required").nullable(),
    }),
    province: Yup.object().shape({
      value: Yup.string().required("This field is required"),
    }),
    barangay: Yup.object().shape({
      value: Yup.string().required("This field is required"),
    }),
    addressType: Yup.string().required("This field is required"),
    // zipCode: Yup.object().shape({
    //   value: Yup.string().required("This field is required").nullable(),
    // }),
  }),
  otherSpecialization: Yup.string().when("specialization", {
    is: (specialization) => specialization.value === "Other",
    then: Yup.string().required("This field is required"),
  }),
  authorizedPerson: Yup.object().when("contactPerson", {
    is: (contactPerson) => contactPerson.length === 0,
    then: Yup.object().shape({
      fullName: Yup.string().required("This field is required"),
      contactNumber: Yup.string()
        // .min(
        //   11,
        //   "Please input a valid mobile number in this format: 09991234567"
        // )
        // .max(
        //   11,
        //   "Please input a valid mobile number in this format: 09991234567"
        // )
        // .matches(
        //   /^09[0-9]{9}$/,
        //   "Please input a valid mobile number in this format: 09991234567"
        // )
        .required("This field is required"),
    }),
  }),
})

export const deliveryDetailsSCPWDValidationSchema = Yup.object().shape({
  dispensingChannel: Yup.string().required("This field is required"),
  firstName: Yup.string().required("This field is required"),
  lastName: Yup.string().required("This field is required"),
  mobileNumber: Yup.string()
    .min(11, "Please input a valid mobile number in this format: 09991234567")
    .max(11, "Please input a valid mobile number in this format: 09991234567")
    .matches(
      /^09[0-9]{9}$/,
      "Please input a valid mobile number in this format: 09991234567"
    )
    .required("This field is required"),
  emailAddress: Yup.string()
    .email("Please input a valid email")
    .required("This field is required"),
  deliveryAddress: Yup.object().shape({
    streetAddress: Yup.string().required("This field is required"),
    city: Yup.string().required("This field is required").nullable(),
    province: Yup.string().required("This field is required"),
    addressType: Yup.string().required("This field is required"),
  }),
  scpwdIDNumber: Yup.string().required("This field is required"),
  scpwdId: Yup.object().shape({
    front: Yup.object().shape({
      path: Yup.string().required("Please upload the necessary file"),
    }),
    back: Yup.object().shape({
      path: Yup.string().required("Please upload the necessary file"),
    }),
  }),
})

export const healthSurveyValidationSchema = Yup.object().shape({
  psychiatryPreferredPsychiatrist: Yup.string().required(
    "This field is required"
  ),
  psychiatryConsultationFrequency: Yup.string().required(
    "This field is required"
  ),
  psychiatryConsultationCost: Yup.string().required("This field is required"),
  willingnessToPay1k: Yup.string().required("This field is required"),
  willingnessToPay2k: Yup.string().required("This field is required"),
  willingnessToPay3k: Yup.string().required("This field is required"),
  willingnessToPay4k: Yup.string().required("This field is required"),
  willingnessToPayAbove4k: Yup.string().required("This field is required"),
})

export const specialOrderValidationSchema = Yup.object().shape({
  patientName: Yup.string().required("This field is required")
})

export const hasTakenThisDrugChoices = ["Yes", "No"]
export const howWellMedicineWorksChoices = ["Well", "Okay", "Not well"]

export const deliveryDetailsInitialValues = {
  firstName: "",
  lastName: "",
  employeeNumber: "",
  mobileNumber: "",
  emailAddress: "",
  deliveryAddress: {
    streetAddress: "",
    city: "",
    province: "",
    notes: "",
  },
}

export const preferredDeliveryTimeChoices = [
  {
    label: "Deliver any time",
    value: "Deliver any time",
  },
  {
    label: "Deliver during office hours",
    value: "Deliver during office hours",
  },
]

export const prescribedMedicineChoices = [
  {
    label: "Abiraterone acetate 250mg Tablet (Zytiga)",
    value: "Abiraterone acetate 250mg Tablet (Zytiga)",
    isSolid: true,
    illnesses: [
      {
        label: "Metastatic Castrate-Resistant Prostate Cancer (Pre-Chemo)",
        value: "Metastatic Castrate-Resistant Prostate Cancer (Pre-Chemo)",
      },
      {
        label: "Metastatic Castrate-Resistant Prostate Cancer (Post-Chemo)",
        value: "Metastatic Castrate-Resistant Prostate Cancer (Post-Chemo)",
      },
      {
        label: "Metastatic Hormone Sensitive Prostate Cancer",
        value: "Metastatic Hormone Sensitive Prostate Cancer",
      },
      {
        label: "Other (please specify)",
        value: "Other",
      },
    ],
  },
  {
    label: "Bortezomib 3.5mg Solution for Injection (Velcade)",
    value: "Bortezomib 3.5mg Solution for Injection (Velcade)",
    isSolid: false,
    illnesses: [
      {
        label: "Multiple Myeloma",
        value: "Multiple Myeloma",
      },
      {
        label: "Mantle Cell Lymphoma",
        value: "Mantle Cell Lymphoma",
      },
      {
        label: "Other (please specify)",
        value: "Other",
      },
    ],
  },
  {
    label: "Ibrutinib 140mg Capsule (Imbruvica)",
    value: "Ibrutinib 140mg Capsule (Imbruvica)",
    isSolid: true,
    illnesses: [
      {
        label: "Chronic Lymphocytic Leukemia",
        value: "Chronic Lymphocytic Leukemia",
      },
      {
        label: "Mantle Cell Lymphoma",
        value: "Mantle Cell Lymphoma",
      },
      {
        label: "Waldenstrom Macroglobulinemia",
        value: "Waldenstrom Macroglobulinemia",
      },
      {
        label: "Other (please specify)",
        value: "Other",
      },
    ],
  },
  {
    label: "Bendamustine hydrochloride 25mg Vial (Ribomustin)",
    value: "Bendamustine hydrochloride 25mg Vial (Ribomustin)",
    isSolid: false,
    illnesses: [
      {
        label: "Chronic Lymphocytic Leukemia",
        value: "Chronic Lymphocytic Leukemia",
      },
      {
        label: "Indolent Non-Hodgkin's Lymphoma",
        value: "Indolent Non-Hodgkin's Lymphoma",
      },
      {
        label: "Multiple Myeloma",
        value: "Multiple Myeloma",
      },
      {
        label: "Other (please specify)",
        value: "Other",
      },
    ],
  },
  {
    label: "Bendamustine hydrochloride 100mg Vial (Ribomustin)",
    value: "Bendamustine hydrochloride 100mg Vial (Ribomustin)",
    isSolid: false,
    illnesses: [
      {
        label: "Chronic Lymphocytic Leukemia",
        value: "Chronic Lymphocytic Leukemia",
      },
      {
        label: "Indolent Non-Hodgkin's Lymphoma",
        value: "Indolent Non-Hodgkin's Lymphoma",
      },
      {
        label: "Multiple Myeloma",
        value: "Multiple Myeloma",
      },
      {
        label: "Other (please specify)",
        value: "Other",
      },
    ],
  },
  {
    label: "Ustekinumab 45mg/0.5mL Pre-filled syringe (Stelara)",
    value: "Ustekinumab 45mg/0.5mL Pre-filled syringe (Stelara)",
    isSolid: false,
    illnesses: [
      {
        label: "Psoriatic Arthritis",
        value: "Psoriatic Arthritis",
      },
      {
        label: "Plaque Psoriasis",
        value: "Plaque Psoriasis",
      },
      {
        label: "Other (please specify)",
        value: "Other",
      },
    ],
  },
  {
    label: "Golimumab 50mg/0.5mL Auto-injection (Simponi)",
    value: "Golimumab 50mg/0.5mL Auto-injection (Simponi)",
    isSolid: false,
    illnesses: [
      {
        label: "Rheumatoid Arthritis",
        value: "Rheumatoid Arthritis",
      },
      {
        label: "Ankylosing Spondylitis",
        value: "Ankylosing Spondylitis",
      },
      {
        label: "Psoriatic Arthritis",
        value: "Psoriatic Arthritis",
      },
      {
        label: "Ulcerative Colitis",
        value: "Ulcerative Colitis",
      },
      {
        label: "Other (please specify)",
        value: "Other",
      },
    ],
  },
  {
    label: "Paliperidone 50mg Injection (Invega Sustenna)",
    value: "Paliperidone 50mg Injection (Invega Sustenna)",
    isSolid: false,
    illnesses: [
      {
        label: "Schizophrenia",
        value: "Schizophrenia",
      },
      {
        label: "Other (please specify)",
        value: "Other",
      },
    ],
  },
  {
    label: "Paliperidone 75mg Injection (Invega Sustenna)",
    value: "Paliperidone 75mg Injection (Invega Sustenna)",
    isSolid: false,
    illnesses: [
      {
        label: "Schizophrenia",
        value: "Schizophrenia",
      },
      {
        label: "Other (please specify)",
        value: "Other",
      },
    ],
  },
  {
    label: "Paliperidone 100mg Injection (Invega Sustenna)",
    value: "Paliperidone 100mg Injection (Invega Sustenna)",
    isSolid: false,
    illnesses: [
      {
        label: "Schizophrenia",
        value: "Schizophrenia",
      },
      {
        label: "Other (please specify)",
        value: "Other",
      },
    ],
  },
  {
    label: "Paliperidone 150mg Injection (Invega Sustenna)",
    value: "Paliperidone 150mg Injection (Invega Sustenna)",
    isSolid: false,
    illnesses: [
      {
        label: "Schizophrenia",
        value: "Schizophrenia",
      },
      {
        label: "Other (please specify)",
        value: "Other",
      },
    ],
  },
  {
    label: "Infliximab 100mg Vial (Remicade)",
    value: "Infliximab 100mg Vial (Remicade)",
    isSolid: false,
    illnesses: [
      {
        label: "Rheumatoid Arthritis",
        value: "Rheumatoid Arthritis",
      },
      {
        label: "Ankylosing Spondylitis",
        value: "Ankylosing Spondylitis",
      },
      {
        label: "Psoriatic Arthritis",
        value: "Psoriatic Arthritis",
      },
      {
        label: "Plaque Psoriasis",
        value: "Plaque Psoriasis",
      },
      {
        label: "Adult Crohn's Disease",
        value: "Adult Crohn's Disease",
      },
      {
        label: "Pediatric Crohn's Disease",
        value: "Pediatric Crohn's Disease",
      },
      {
        label: "Adult Ulcerative Colitis",
        value: "Adult Ulcerative Colitis",
      },
      {
        label: "Pediatric Ulcerative Colitis",
        value: "Pediatric Ulcerative Colitis",
      },
      {
        label: "Other (please specify)",
        value: "Other",
      },
    ],
  },
  {
    label: "Daratumumab 100mg Vial (Darzalex)",
    value: "Daratumumab 100mg Vial (Darzalex)",
    isSolid: false,
    illnesses: [
      {
        label: "Multiple Myeloma",
        value: "Multiple Myeloma",
      },
      {
        label: "Other (please specify)",
        value: "Other",
      },
    ],
  },
  {
    label: "Daratumumab 400mg Vial (Darzalex)",
    value: "Daratumumab 400mg Vial (Darzalex)",
    isSolid: false,
    illnesses: [
      {
        label: "Multiple Myeloma",
        value: "Multiple Myeloma",
      },
      {
        label: "Other (please specify)",
        value: "Other",
      },
    ],
  },
]

export const specialOrderPatientInitialValues = {
  patientName: "",
  whyMedicineWasPrescribed: {
    value: "",
  },
  otherWhyMedicineWasPrescribed: "",
  diseaseType: "",
  lineOfTreatment: "",
  therapyType: "",
  combinationTherapyMedicines: "",
  medicinePrescribed: "",
}

export const paymentDetailsInitialValues = {
  paymentOption: "",
  changeFor: "",
  courierOption: "",
}

export const bestTimeToCallOptions = ["9AM-12PM", "12PM-3PM", "3PM-5PM"]
