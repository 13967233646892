import React, { Fragment, useContext, useEffect } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faUserCircle, faListAlt } from "@fortawesome/free-solid-svg-icons"

import { AppContext } from "../../context/AppContext"

import FormInput from "../Elements/Form/FormInput"
import Dropzone from "../Upload/Dropzone"
import { isObjectEmpty } from "../../services/general"

import ImageCard from "./SCPWDDetails/ImageCard"

const SCPWDDetails = ({ setFieldValue, errors, submitCount }) => {
  const { state } = useContext(AppContext)
  const { scpwdId } = state

  const MAX_FILE_SIZE_IN_BYTES = 3145728
  const MAX_FILE_COUNT = 1

  useEffect(() => {
    setFieldValue("scpwdId", scpwdId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scpwdId])

  return (
    <Fragment>
      <div className="notification is-light">
        Please note that SCPWD orders are processed only if the following are
        uploaded: SCPWD ID (front and back), and prescription medicines written
        on doctor's pad.
      </div>
      <FormInput
        name="scpwdIDNumber"
        label="SC/PWD ID Number"
        placeholder="54615"
        isRequired
      />

      <div className="columns">
        <div className="column">
          {!isObjectEmpty(scpwdId?.front) ? (
            <ImageCard image={scpwdId?.front} type="FRONT" />
          ) : (
            <Dropzone
              maxByteSize={MAX_FILE_SIZE_IN_BYTES}
              maxFileCount={MAX_FILE_COUNT}
              docType="SCPWD_FRONT"
            >
              <FontAwesomeIcon icon={faUserCircle} className="mb-1" size="2x" />
              <br />
              <small>Upload SC/PWD ID front</small>
            </Dropzone>
          )}
          {submitCount > 0 && errors?.scpwdId?.front?.path && (
            <p className="help has-text-danger">
              {errors?.scpwdId?.front?.path}
            </p>
          )}
        </div>
        <div className="column">
          {!isObjectEmpty(scpwdId?.back) ? (
            <ImageCard image={scpwdId?.back} type="BACK" />
          ) : (
            <Dropzone
              maxByteSize={MAX_FILE_SIZE_IN_BYTES}
              maxFileCount={MAX_FILE_COUNT}
              docType="SCPWD_BACK"
            >
              <FontAwesomeIcon icon={faListAlt} className="mb-1" size="2x" />
              <br />
              <small>Upload SC/PWD ID back</small>
            </Dropzone>
          )}
          {submitCount > 0 && errors?.scpwdId?.back?.path && (
            <p className="help has-text-danger">
              {errors?.scpwdId?.back?.path}
            </p>
          )}
        </div>
      </div>
    </Fragment>
  )
}

export default SCPWDDetails
