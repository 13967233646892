import React, { useContext, useEffect } from "react"
import { Link, navigate } from "gatsby"
import { Formik, Form } from "formik"
import PropTypes from "prop-types"

import Section from "../Elements/Section"
import ActionButtons from "../Elements/ActionButtons"
import SCPWDDetails from "../PersonalDetails/SCPWDDetails"
import InvalidFormMessage from "../Elements/InvalidFormMessage"
import PatientDetails from "../PersonalDetails/PatientDetails"
import DeliveryAddressSection from "./DeliveryDetailsForm/DeliveryAddressSection"
import PrescriptionInformationSection from "./DeliveryDetailsForm/PrescriptionInformationSection"

import {
  deliveryDetailsValidationSchema,
  deliveryDetailsSCPWDValidationSchema,
} from "./utils/formData"
import { AppContext } from "../../context/AppContext"
import { isObjectEmpty } from "../../services/general"
import { getSignedInUser } from "../Auth/services/user"
import { useSession } from "../../services/hooks/useSession"
import { getContextFromSession } from "../../context/services/context"

import { GATSBY_AWS_S3_BUCKET_NAME } from "gatsby-env-variables"

/**
 * @param {string} backRoute string
 * @param {string} nextRoute string
 */

const DeliveryDetailsForm = ({ backRoute, nextRoute }) => {
  const { dispatch, state } = useContext(AppContext)
  const { 
    sessionFormValues, 
    isFormValuesEmpty, 
    initializeFormValues, 
    handleFormChange, 
    clearFormValues 
  } = useSession("orderRequest")
  let formValues = getContextFromSession()
  if (isObjectEmpty(formValues)) formValues = state

  const addressFieldNames = {
    addressType: `deliveryAddress.addressType`,
    streetAddress: `deliveryAddress.streetAddress`,
    city: `deliveryAddress.city`,
    province: `deliveryAddress.province`,
    barangay: `deliveryAddress.barangay`,
  }
  const formValidationSchema =
    state?.epharmacy?.coupon?.code === "SCPWD"
      ? deliveryDetailsSCPWDValidationSchema
      : deliveryDetailsValidationSchema

  useEffect(() => {
    dispatch({ type: "GET_CONTEXT_FROM_SESSION" })
    initializeFormValues()
  }, [])

  const handleSubmit = async (values) => {
    clearFormValues()

    await dispatch({
      type: "SAVE_CONTEXT_TO_SESSION",
      payload: {
        ...state,
        epharmacy: { ...state.epharmacy, ...values },
      },
    })
    await dispatch({ type: "SAVE_DELIVERY_DETAILS", payload: values })

    navigate(nextRoute)
  }

  // fetched signed in user data
  let signedInUser = getSignedInUser()
  let userData = signedInUser?.userData
  let userAddresses = signedInUser?.addresses

  let program = userData?.programInfo.find(
    (program) => program?.subdomain === GATSBY_AWS_S3_BUCKET_NAME
  )

  if (userData)
    formValues.epharmacy = {
      ...formValues.epharmacy,
      ...userData,
      firstName: formValues?.epharmacy?.firstName || userData?.firstName,
      lastName: formValues?.epharmacy?.lastName || userData?.lastName,
      mobileNumber:
        formValues?.epharmacy?.mobileNumber || userData?.mobileNumber,
      emailAddress: formValues?.epharmacy?.emailAddress || userData?.email,
      doctorName: formValues?.epharmacy?.doctorName || program?.doctor,
      hospitalName: {
        value:
          formValues?.epharmacy?.hospitalName?.value || program?.hospitalClinic,
        label:
          formValues?.epharmacy?.hospitalName?.value || program?.hospitalClinic,
      },
    }

  return (
    <Formik
      initialValues={isFormValuesEmpty ? { ...formValues.epharmacy, ...formValues.scpwdId } : { ...sessionFormValues }}
      onSubmit={handleSubmit}
      validationSchema={formValidationSchema}
      enableReinitialize
    >
      {({
        values,
        setFieldValue,
        handleChange,
        errors,
        submitCount,
        isValid,
      }) => (
        <Form onChange={() => handleFormChange(values)}>
          <Section title="Patient Details">
            <PatientDetails
              values={values}
              onChange={(event) => handleChange(event)}
            />
          </Section>
          {state?.epharmacy?.coupon?.code === "SCPWD" && (
            <Section title="Senior Citizen / Person With Disability ID">
              <SCPWDDetails
                submitCount={submitCount}
                setFieldValue={setFieldValue}
                errors={errors}
              />
            </Section>
          )}
          <PrescriptionInformationSection values={values} />
          <DeliveryAddressSection
            setFieldValue={setFieldValue}
            values={values}
            addressFieldNames={addressFieldNames}
            handleChange={handleChange}
            addresses={userAddresses?.addresses}
          />
          {!isValid && submitCount > 0 && (
            <InvalidFormMessage color="danger" name={"missingRequiredFields"}>
              {!values?.deliveryAddress?.barangay?.value ? (
                <span>
                  Your selected delivery address is not updated.{" "}
                  <Link
                    to="/profile/edit-address"
                    state={{
                      nextPath: "/epharmacy/checkout",
                      backPath: "/epharmacy/checkout",
                      address: {
                        ...values?.deliveryAddress,
                        type: values?.deliveryAddress?.addressType,
                      },
                    }}
                  >
                    Update Address
                  </Link>
                </span>
              ) : null}
            </InvalidFormMessage>
          )}
          <ActionButtons
            submit={{ label: "Next: Select Channel" }}
            back={{ label: "Back", link: backRoute }}
          />
        </Form>
      )}
    </Formik>
  )
}

DeliveryDetailsForm.propTypes = {
  backRoute: PropTypes.string,
  nextRoute: PropTypes.string,
}

export default DeliveryDetailsForm
