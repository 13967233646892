import React from "react"
import Layout from "../../components/Layout/Layout"
import Container from "../../components/Layout/Container"
import DeliveryDetailsForm from "../../components/Epharmacy/DeliveryDetailsForm"

export default ({ location }) => (
  <Layout
    title="Checkout"
    subtitle={
      <span>
        Please answer the following questions accurately.
        <br className="is-hidden-mobile" /> Your information will be used as a
        basis for your delivery.
      </span>
    }
    seoTitle="Checkout"
    process="epharmacy"
    display={{ footer: false, helpCenterBanner: false }}
    isPrivate
    isPatient
  >
    <Container isCentered desktop={6} fullhd={6}>
      <DeliveryDetailsForm
        backRoute="/epharmacy/upload"
        nextRoute="/epharmacy/shipping-and-payment"
      />
    </Container>
  </Layout>
)
