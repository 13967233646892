import React from "react"
import Section from "../../Elements/Section"
import FormInput from "../../Elements/Form/FormInput"
import FormSelect from "../../Elements/Form/FormSelect"

import {
  handleLoadHospitalOptions,
  handleOnHospitalChange,
} from "../../Enrollment/services/handleSelectHospitals"
import hospitals from "../../Enrollment/utils/hospitals.json"

const PrescriptionInformationSection = ({ values }) => (
  <Section title="Prescription Details">
    <FormInput
      name="doctorName"
      label="Prescribing Doctor"
      placeholder="Juana Dela Cruz"
      isRequired
    />
    <FormSelect
      name="hospitalName"
      options={hospitals}
      value={values?.hospitalName}
      label="Hospital/Clinic"
      helper="Please select 'Other' if you can't find your hospital/clinic in the list."
      placeholder="Jose delos Santos Medical Center"
      loadOptions={handleLoadHospitalOptions}
      onChange={handleOnHospitalChange("hospitalName")}
      defaultOptions
      isRequired
      isAsync
    />
    {values?.hospitalName?.value === "Other" && (
      <FormInput
        name="otherHospital"
        label="Please indicate your hospital/clinic"
        placeholder="Philippine General Hospital"
        isRequired
      />
    )}
  </Section>
)

export default PrescriptionInformationSection
